/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as emailjs from 'emailjs-com';
import './src/styles/global.scss'

if (!document.body.style.scrollBehavior) {
  require('scroll-behavior-polyfill');
  document.body.style.scrollBehavior = 'smooth';
}

emailjs.init('user_RZHxqjm4E8VXo7VXipRxM');
